import fb from '@/store/gobi-firestore';
import { FirestoreColFakeGet } from './firestoreColFakeGet';
import { FirestoreDocFakeGet } from './firestoreDocFakeGet';

interface FirestoreColFakeGetDic {
	[key: string]: FirestoreColFakeGet,
}
interface FirestoreDocFakeGetDic {
	[key: string]: FirestoreDocFakeGet,
}


const db = fb.db;

export class FirestoreFakeGet {

	public static doc(path: string) {
		if (!FirestoreFakeGet._docs[path]) {
			FirestoreFakeGet._docs[path] = new FirestoreDocFakeGet(path);
		}
		return FirestoreFakeGet._docs[path];
	}
	public static collection(path: string) {
		if (!FirestoreFakeGet._cols[path]) {
			FirestoreFakeGet._cols[path] = new FirestoreColFakeGet(path);
		}
		return FirestoreFakeGet._cols[path];
	}

	public static dispose() {
		for (const path in FirestoreFakeGet._cols) {
			if (FirestoreFakeGet._cols.hasOwnProperty(path)) {
				const getter = FirestoreFakeGet._cols[path];
				getter.dispose();
			}
		}
		for (const path in FirestoreFakeGet._docs) {
			if (FirestoreFakeGet._docs.hasOwnProperty(path)) {
				const getter = FirestoreFakeGet._docs[path];
				getter.dispose();
			}
		}
	}

	protected static _cols: FirestoreColFakeGetDic = {};
	protected static _docs: FirestoreDocFakeGetDic = {};
}

export default FirestoreFakeGet;
