import {
    getModule,
    Module,
    MutationAction,
    VuexModule,
    Mutation,
    Action,
} from 'vuex-module-decorators';
import store from '@/store';

@Module({
    namespaced: true,
    name: 'globalx',
    store,
    dynamic: true,
})
class GlobalModule extends VuexModule {

    public version = '';
    public hasUpdate = false;

    @Mutation
    public m_updateVersion(version: string) {
        if (this.version && this.version !== version) {
            this.hasUpdate = true;
        }
        this.version = version;
    }
    @Action
    public updateVersion(version: string) {
        this.m_updateVersion(version);
    }

}
export default getModule(GlobalModule);
