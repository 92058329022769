
import { Vue, Component, Prop } from 'vue-property-decorator';
import ProgressToast from './ProgressToast.vue';

import progressToastx from '@/store/modules/progressToastx';

@Component({
	components: {
		ProgressToast,
	},
})
export default class ProgressToaster extends Vue {
	@Prop({ default: false }) public debug!: boolean;
	public get toasts() {
		return progressToastx.toasts;
	}

	// private _toasts:ToastData[] = []

	// public get toasts(){
	// 	return this._toasts;
	// }
	public count = 0;

	public addToast() {
		const id = progressToastx.add({
			title: `Add Client #${this.count++}`,
			state: 'Adding...',
			destroyWhenHidden: false,
		});
		this.$nextTick().then(() => {});
	}
	public deleteToast() {
		const index = Math.floor(Math.random() * this.toasts.length);
		// progressToastx.remove(this.toasts[index].id);
		progressToastx.done({ id: this.toasts[index].id });
	}
}
