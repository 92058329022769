
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import userx from '@/store/modules/userx';

@Component({
	components: {},
})
export default class LogoutModal extends Vue {
	public showModal() {
		const modal = this.$refs.logoutModal as BModal;
		if (modal) {
			modal.show();
		}
	}

	public onOk() {
		userx.signOut();
	}

	public created() {
		this.$root.$on('logout', this.showModal);
	}
}
