import {
	getModule,
	Module,
	MutationAction,
	VuexModule,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import store from '@/store';
import fb from '@/store/gobi-firestore';
import {
	User, LoginSubmission, ProcessState, StateKeyPairs, LoginStatus,
	TypicalResponse, SetSupportLvlResponse, UserHistory, AccountMinimumData,
	AccountMinimumDataDic, SignInAndResetPasswordSubmission, Signature,
} from '@/store/models.def';

import Vue from 'vue';
import FirestoreFakeGet from '@/util/fakeget/firestoreFakeGet';

import { signInAsLiveDemo as liveDemo } from '@/helpers/signInAsLiveDemo';
import { TERMS_VERSION, PRIVACY_POLICY_VERSION } from '@/components/auth/termsPolicy/version';

(window as any).store = store;
@Module({
	namespaced: true,
	name: 'userx',
	store,
	dynamic: true,
})
class UsersModule extends VuexModule {

	public signinState: ProcessState = ProcessState.Undefined;
	public loginStatus1: LoginStatus = 'loading';
	public holdLoginStatus = false;
	public user: User | null = null;
	public lastVisitedAccount: 'loading' | string | null = 'loading';
	public lastLoginTime: number | null = null;
	public visitedAccounts: string[] = [];
	public myAccountsDic: AccountMinimumDataDic | null = null;
	public termsSignature: Signature | null = null;
	public privacySignature: Signature | null = null;
	public userDocLoaded: boolean = false;

	public emailVerificationSent = false;
	public recentInvitedEmails: Set<string> = new Set<string>();

	public get loginStatus(): LoginStatus {
		if (this.holdLoginStatus) {
			return 'loading';
		}
		return this.loginStatus1;
	}

	get displayName(): string | null | undefined {
		return (this.user && this.user.displayName);
	}
	get uid(): string | null | undefined {
		return (this.user && this.user.uid);
	}
	get supportLvl(): number | null {
		if (!this.user) { return null; }
		if (this.user.supportLvl === undefined) { return null; }
		return this.user.supportLvl;
	}

	get needCompleteRegistration(): boolean | undefined {
		if (!this.user || this.user.noPassword === undefined) { return undefined; }
		if (this.user.displayName === '' || this.user.noPassword === true) {
			return true;
		}
		if (!this.userDocLoaded) {
			return undefined;
		}
		if (!this.termsSignature || this.termsSignature.signedVersion < TERMS_VERSION ||
			!this.privacySignature || this.privacySignature.signedVersion < PRIVACY_POLICY_VERSION
		) {
			return true;
		}
		return false;
	}

	get myAccounts(): AccountMinimumData[] | null {
		if (!this.myAccountsDic) { return null; }
		const accounts: AccountMinimumData[] = [];
		for (const key in this.myAccountsDic) {
			if (this.myAccountsDic.hasOwnProperty(key)) {
				const account = this.myAccountsDic[key];
				if (!account) { continue; }
				account.unvisited = !this.visitedAccounts.includes(account.accountId);
				accounts.push(account);
			}
		}
		accounts.sort((a, b) => (a.createdAt - b.createdAt));
		return accounts;
	}

	get numUnvisitedAccounts(): number {
		if (!this.myAccounts) { return 0; }
		return this.myAccounts.reduce((num, account) => {
			if (account.unvisited) { num++; }
			return num;
		}, 0 as number);
	}

	@Mutation
	public m_updateUser(user: User | null) {
		this.user = user;
		if (!this.user) { this.emailVerificationSent = false; }
	}
	@Mutation
	public m_updateHistory(userHistory: UserHistory) {
		if (userHistory.lastLoginTime !== undefined) {
			this.lastLoginTime = userHistory.lastLoginTime;
		}
		if (userHistory.lastVisitedAccount !== undefined) {
			this.lastVisitedAccount = userHistory.lastVisitedAccount;
		}
		if (userHistory.visitedAccounts !== undefined) {
			this.visitedAccounts = userHistory.visitedAccounts;
		}
		if (userHistory.termsSignature !== undefined) {
			this.termsSignature = userHistory.termsSignature;
		}
		if (userHistory.privacySignature !== undefined) {
			this.privacySignature = userHistory.privacySignature;
		}
		if (userHistory.userDocLoaded !== undefined) {
			this.userDocLoaded = userHistory.userDocLoaded;
		}
	}
	@Mutation
	public m_updateMyAccounts(accountsDic: AccountMinimumDataDic) {
		this.myAccountsDic = accountsDic;
	}
	@Mutation
	public m_addInvitedEmail(email: string) {
		this.recentInvitedEmails = new Set(this.recentInvitedEmails.add(email));
	}

	@Mutation
	public m_updateState(keyPair: StateKeyPairs) {
		(this as any)[keyPair.key] = keyPair.state;
	}

	@Mutation
	public m_updateLoginStatus(status: LoginStatus) {
		this.loginStatus1 = status;
	}
	@Mutation
	public m_updateHoldLoginStatus(status: boolean) {
		this.holdLoginStatus = status;
	}
	@Mutation
	public m_emailVerificationSent() {
		this.emailVerificationSent = true;
	}

	@Action
	public loginLocal(user: User | null = null) {
		this.m_updateUser(user);
		this.m_updateLoginStatus((user) ? 'logged in' : 'logged out');
		(window as any).userx = this;
	}

	@Action
	public updateUser(user: User | null = null) {
		const currentUser: User | null | undefined = (this.context.state as any).user;
		if (user && user.uid === this.uid) { this.m_updateUser(JSON.parse(JSON.stringify(user))); }
	}
	@Action
	public updateHistory(userHistory: UserHistory) {
		this.m_updateHistory(userHistory);
	}
	@Action
	public updateMyAccounts(accountsDic: AccountMinimumDataDic) {
		this.m_updateMyAccounts(accountsDic);
	}

	@Action
	public async logoutLocal() {
		this.loginLocal(); // passing null as arg
		this.m_updateHistory({ lastVisitedAccount: null, lastLoginTime: null, visitedAccounts: [] });
		this.m_updateMyAccounts({});
	}

	@Action
	public async signIn(userSubmit: LoginSubmission) {
		this.m_updateState({ key: 'signinState', state: ProcessState.Await });
		const result = await fb.loginUser(userSubmit);
		if (result.success) {
			this.m_updateState({ key: 'signinState', state: ProcessState.Succeed });
		} else {
			this.m_updateState({ key: 'signinState', state: ProcessState.Failed });
		}
		return result;
	}
	@Action
	public async signOut() {
		await fb.logoutUser();
		FirestoreFakeGet.dispose();
	}

	@Action
	public async updateDisplayName(displayName: string): Promise<TypicalResponse> {
		if (!this.user) { return { success: false, errorMessage: 'No User' }; }
		if (this.user.displayName === displayName) {
			return { success: true };
		}
		this.user.displayName = displayName;
		this.m_updateUser(this.user);
		const result = await fb.updateDisplayName(displayName);
		return result;
	}
	@Action
	public async updatePassword(password: string): Promise<TypicalResponse> {
		if (!this.user) { return { success: false, errorMessage: 'No User' }; }
		const result = await fb.updatePassword(password);
		if (result.success) {
			this.user.noPassword = false;
			this.m_updateUser(this.user);
		}
		return result;
	}
	@Action
	public async signInAndResetPassword(passwords: SignInAndResetPasswordSubmission): Promise<TypicalResponse> {
		if (!this.user) { return { success: false, errorMessage: 'No User' }; }
		const { oldPassword, newPassword } = passwords;
		const result = await fb.signInAndResetPassword(oldPassword, newPassword);
		if (result.success) {
			this.user.noPassword = false;
			this.m_updateUser(this.user);
		}
		return result;
	}
	@Action
	public async sendSignInEmail(email: string): Promise<TypicalResponse> {
		const result = await fb.sendSignInEmail(email);
		if (result.success) {
			this.m_addInvitedEmail(email);
		}
		return result;
	}
	@Action
	public async sendEmailVerification(): Promise<TypicalResponse> {
		if (!this.user) { return { success: false, errorMessage: 'No User' }; }
		if (this.emailVerificationSent) { return { success: false, errorMessage: 'Verification Email Sent' }; }
		const result = await fb.sendEmailVerification();
		if (result.success) {
			this.m_emailVerificationSent();
		}
		return result;
	}
	@Action
	public async sendPasswordResetEmail(email: string): Promise<TypicalResponse> {
		const result = await fb.sendPasswordResetEmail(email);
		return result;
	}
	@Action
	public async setSupportLvl(target: { email: string, supportLvl: number }): Promise<SetSupportLvlResponse> {
		const result = await fb.setSupportLvl(target.email, target.supportLvl);
		return result;
	}

	@Action
	public async signInAsLiveDemo() {
		try {
			this.m_updateHoldLoginStatus(true);
			await liveDemo();
			this.m_updateHoldLoginStatus(false);

		} catch (error) {
			console.error(error);
			this.m_updateHoldLoginStatus(false);
		}
	}

	@Action public signTerms(type: 'terms' | 'privacyPolicy') {
		const submissionType = type === 'terms' ? 'termsSignature' : 'privacySignature';
		const submission: any = {};
		submission[submissionType] = {
			signedAt: Date.now(),
			signedVersion: type === 'terms' ? TERMS_VERSION : PRIVACY_POLICY_VERSION,
		};
		this.m_updateHistory(submission);
		fb.signTerms(type);
	}

}
export default getModule(UsersModule);
