
const hasWindowSupport = typeof window !== 'undefined';
const w: any = hasWindowSupport ? window : {};
// `requestAnimationFrame()` convenience method
const requestAF =
	w.requestAnimationFrame ||
	w.webkitRequestAnimationFrame ||
	w.mozRequestAnimationFrame ||
	w.msRequestAnimationFrame ||
	w.oRequestAnimationFrame ||
	// Fallback, but not a true polyfill
	// Only needed for Opera Mini
	/* istanbul ignore next */
	((cb: () => void) => setTimeout(cb, 16));

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BToastProgress from './BToastProgress.vue';
import progressToastx from '@/store/modules/progressToastx';
import { ToastData } from '@/store/models.def';
import { BToast } from 'bootstrap-vue';

@Component({
	name: 'ProgressToast',
	components: {},
})
export default class ProgressToast extends Vue {
	@Prop() public data!: ToastData;

	public visible: boolean = true;

	public get variant() {
		return this.data.variant || 'warning';
	}
	public get solid() {
		return 'true';
	}
	public get title() {
		return this.data.title;
	}
	public get state() {
		return this.data.state;
	}
	public get destroyWhenHidden() {
		return this.data.destroyWhenHidden;
	}
	private _myToast: BToast | null = null;

	@Watch('variant')
	public onVariantChanged(val: string, oldVal: string) {
		this._show();
	}
	@Watch('title')
	public onTitleChanged(val: string, oldVal: string) {
		this._show();
	}
	@Watch('state')
	public onStateChanged(val: string, oldVal: string) {
		this._show();
	}

	@Watch('destroyWhenHidden')
	public async onDestroyWhenHiddenChanged(val: boolean, oldVal: boolean) {
		requestAF(() => {
			if (this.destroyWhenHidden && !this.visible) { this._handleDestroy(); }
			},
		);
	}

	public mounted() {
		this.$root.$bvToast.show(this.data.id);
		this._setupMyToast(this.$children[0] as BToast);
		this.$nextTick(() => {});
	}

	private _show() {
		this.$root.$bvToast.show(this.data.id);
		const toast: any = this._myToast;
		toast.startDismissTimer();
		// prevent premature destroy
		const oldDestroy = this.destroyWhenHidden;
		if (oldDestroy && !this.visible) {
			this.data.destroyWhenHidden = false;
			this.$nextTick(() => {
				this.data.destroyWhenHidden = oldDestroy;
			});
		}
	}

	private _setupMyToast(toast: BToast) {
		const onHidden = () => {
			if (this.destroyWhenHidden) {
				this._handleDestroy();
			}
		};
		toast.$on('hidden', onHidden);
		toast.$once('hook:destroyed', () => {
			toast.$off('hidden', onHidden);
		});
		this._myToast = toast;
	}

	private _handleDestroy() {
		progressToastx.remove(this.data.id);
	}
}
