import firebase from 'firebase/app';
import fb from '@/store/gobi-firestore';

const db = fb.db;

interface PromiseData {
	resolve: (doc: any | null) => void,
	reject: (error: Error) => void,
}

export class FirestoreDocFakeGet {
	protected _path!: string;
	protected _ref!: firebase.firestore.DocumentReference;
	protected _unsubscribe: (() => void) | null = null;

	protected _doc: firebase.firestore.DocumentSnapshot | null = null;
	protected _error: Error | null = null;
	protected _data: any | null = null;

	protected _unresolved: PromiseData[] = [];

	constructor(path: string) {
		this._path = path;
		this._ref = db.doc(path);

	}

	public get() {
		if (!this._unsubscribe) {
			this._unsubscribe = this._ref.onSnapshot(
				{ includeMetadataChanges: true },
				(doc) => {
					if (!doc.metadata.fromCache) {
						this._doc = doc;
						this._error = null;
						if (doc.exists) {
							this._data = doc.data();
						} else {
							this._data = null;
						}
						while (this._unresolved.length > 0) {
							this._unresolved.shift()!.resolve(this._data);
						}
					}
				}, (error) => {
					this._doc = null;
					this._error = error;
					while (this._unresolved.length > 0) {
						this._unresolved.shift()!.reject(error);
					}
				});
		}
		if (this._doc) {
			return new Promise(
				(resolve: (doc: any | null) => void,
					reject: (error: Error) => void) => {
					resolve(this._data);
				});
		} else if (this._error) {
			return new Promise(
				(resolve: (doc: any | null) => void,
					reject: (error: Error) => void) => {
					reject(this._error!);
				});
		} else {
			return new Promise(
				(resolve: (doc: any | null) => void,
					reject: (error: Error) => void) => {
					this._unresolved.push({ resolve, reject });
				});
		}
	}

	public dispose() {
		if (this._unsubscribe) { this._unsubscribe(); }
		while (this._unresolved.length > 0) {
			this._unresolved.shift()!.reject(new Error('Canceled'));
		}
	}
}

