
import { Dictionary } from 'vue-router/types/router';
import { Moment } from 'moment';
import { FilteredDatabaseHead } from '@/worker/fd/FilteredDatabaseHead';
import { WireValueType } from 'comlink/dist/umd/protocol';

export interface User {
	uid: string,
	email: string,
	displayName: string,
	emailVerified: boolean,

	supportLvl?: number,
	noPassword?: boolean,
}
export interface UserHistory {
	lastVisitedAccount?: string | null,
	lastLoginTime?: number | null,
	visitedAccounts?: string[],
	termsSignature?: Signature,
	privacySignature?: Signature,
	userDocLoaded?: boolean,
}
export interface Signature {
	signedAt: number,
	signedVersion: number,
}

export interface ProfileSubmission {
	displayName?: string,
	photoURL?: string,
}
export interface LoginSubmission {
	email: string,
	password: string,
}
export interface SignInAndResetPasswordSubmission {
	oldPassword: string,
	newPassword: string,
}
export interface TypicalResponse {
	success: boolean,
	errorCode?: string,
	errorMessage?: string,
	error?: any,
}
export interface SetSupportLvlResponse extends TypicalResponse {
	email: string,
	supportLvl: number,
}
export interface StateKeyPairs {
	key: string,
	state: ProcessState
}

export enum ProcessState {
	Undefined,
	Command,
	Preprocess,
	Attempt,
	Await,
	Succeed,
	Failed,
}

export type LoginStatus = 'loading' | 'logged in' | 'logged out';
export type EditState = 'active' | 'sending' | 'done';

export interface ToastData {
	id: string,
	title: string,
	state: string,
	destroyWhenHidden: boolean,
	variant?: string,
}

export const softwareTypes = ['sql', 'autocount'] as const;
export type SoftwareType = typeof softwareTypes[number];

export interface AccountMinimumData {
	accountId: string,
	companyName: string,
	companyRemark?: string,
	createdAt: number,
	creaatedBy?: string,
	unvisited?: boolean,
}
export interface AccountMinimumDataDic {
	[key: string]: AccountMinimumData | null;
}
export interface AccountData {
	accountId: string,
	companyName: string,
	companyRemark?: string,
	softwareType: string,
	interval: number,
	schemaId: string,
	programId: string,
	gdbid: string,
	coldDb: string,
	path: string,
	token?: MwToken,
	oldTokens: MwTokenDic,
	createdBy: string,
	createdAt: number,
	lastSync: number,
	lastUpdate: number,
	expiryTime: number,
	globalLastAvailable: GdbPathMinimum,
	salesLastAvailable: GdbPathMinimum,
	quotationsLastAvailable: GdbPathMinimum,
	purchasesLastAvailable: GdbPathMinimum,
	transactionsLastAvailable: GdbPathMinimum,
	suppliersLastAvailable?: GdbPathMinimum,
	customersLastAvailable?: GdbPathMinimum,
	payrollLastAvailable?: GdbPathMinimum,
}
export interface GdbPathMinimum {
	gdbid: string,
	version: number,
	uploadedAt?: number,
	downloadUrl?: string,
	csvs?: {
		[T in FilterableCardName]?: {
			downloadUrl: string,
			bucketUrl: string,
			size: number,
		}
	}
}
export interface GdbPath extends GdbPathMinimum {
	bucketUrl?: string,
	accountId: string,
	createdAt: number,
}
export interface MwToken {
	id?: string,
	key: string,
	version: number,
	createdAt?: number,
	createdBy?: string,
	forbidden?: boolean,
}

export interface CommandData {
	commandId: string,
	accountId: string,
	type: string,
	status: string,
	issuedAt: number,
	hasError: boolean,
	payload?: any,
	logs?: CommandLog[],
	inactive?: boolean,
	completedAt?: number,
}
export interface CommandLog {
	time?: number,
	level: 'debug' | 'info' | 'error',
	message: string,
}

export interface CreateAccountSubmission {
	companyName: string,
	path: string,
	schemaId?: string,
	interval?: number,
	softwareType?: SoftwareType,
}

export interface CreateAccountResponse extends TypicalResponse {
	accountData?: AccountData;
}

export interface AccountRelatedSubmission {
	accountId: string,
}
export interface UpdateAccountSubmission extends AccountRelatedSubmission {
	path?: string,
	interval?: number,
	'token.forbidden'?: boolean,
}
export interface ChangeSchemaSubmission extends AccountRelatedSubmission {
	schemaId: string,
}
export interface CommandReponse extends TypicalResponse {
	command?: CommandData,
}
export interface ExpireCurrentTokenSubmission extends AccountRelatedSubmission {
	doNotMakeNew?: boolean,
}
export interface ExpireCurrentTokenResponse extends TypicalResponse {
	token?: MwToken,
	oldTokens?: MwTokenDic,
	companyName?: string,
	accountId?: string,
}

export interface MwTokenDic {
	[key: string]: MwToken;
}

export interface ValueText<T = any> {
	value: T,
	text: string,
	disabled?: boolean,
}

export type GdbLoadProgress = 'Idle' | 'Loading' | 'Processing' | 'Completed' | 'Failed' | number;



export interface AmountCard {
	amount: number,
}
export interface DateFilterableCard {
	date: number,
}
export interface AgentFilterableCard {
	agent: string,
}
export interface CompanyFilterableCard {
	code: string,
}
export interface TaxableDetailCard {
	tax: number,
}
export interface DetailItemCard {
	date: number,
	amount: number,
	itemCode: string,
	stockGroup: string,
	costingMethod: number | null,
	quantity: number,
	discount: number,
	tax: number,
	code?: string,
}
export interface IncomingItemCard {
	code: string,
}
export interface OutgoingItemCard {
	code: string,
}
export interface SalesDetailCard {
	date: number,
	amount: number,
	agent: string,
	stockGroup: string,
	itemCode: string,
	code: string,
	costingMethod: number | null,
	quantity: number,
	discount: number,
	tax: number,
	cost: number,
	docNo: string,
}
export interface QuotationDetailCard {
	date: number,
	amount: number,
	agent: string,
	stockGroup: string,
	itemCode: string,
	code: string,
	costingMethod: number | null,
	quantity: number,
	discount: number,
	tax: number,
	outstandingAmount: number,
	docNo: string,
	outstandingQuantity: number,
	transferredAmt: number,
	sequence: number,
}
export interface SalesOrderDetailCard {
	delivery_date: any,
	date: number,
	amount: number,
	agent: string,
	stockGroup: string,
	itemCode: string,
	code: string,
	costingMethod: number | null,
	quantity: number,
	discount: number,
	tax: number,
	outstandingAmount: number,
	docNo: string,
	outstandingQuantity: number,
	transferredAmt: number,
	sequence: number,
}
export interface PurchaseDetailCard {
	date: number,
	amount: number,
	agent: string,
	stockGroup: string,
	itemCode: string,
	code: string,
	costingMethod: number | null,
	quantity: number,
	discount: number,
	tax: number,
	cost: number,
}

export interface TransactionCard {
	date: number,
	cr: number,
	dr: number,
	accType: string,
	specialAccType: string,
	accTypeName: string,
	specialAccTypeName: string,
	amount: number,
	specialAccTypeCode?: string,
}

export interface StockBalanceCard {
	year: number,
	month: number,
	amount: number,
	project: string,
}
export interface QueryObject {
	date?: [number, number],
	agents?: string[],
	stockGroups?: string[],
	customers?: string[],
	suppliers?: string[],
	stockItems?: string[],
}

export interface FilteredDataDic<T> {
	[key: string]: T[];
}
export interface GroupedDataDic<T> {
	[key: string]: T[];
}

export interface CustomerCard {
	code: string,
	companyName: string,
	companyCategory: string,
	companyCategoryDesc: string,
	agent: string,
	agentDesc: string,
	lat: number,
	lng: number,
}

export interface CustomerIvDnCard {
	docType: 'IV' | 'DN',
	docKey: number,
	docNo: string,
	code: string,
	date: number,
	terms: string,
	dueDate: number,
	agent: string,
	currencyRate: number,
	foreignAmount: number,
	amount: number,
	foreignOsAmount: number,
	osAmount: number,
	lastKoDocDate: number,
}
export interface CustomerKoCard {
	toDocType: 'IV' | 'DN',
	toDocKey: number,
	toDocNo: string,
	lastKoDocDate: number,
	dueDate: number,
	date: number,
	currencyRate: number,
	osAmount: number,
	foreignOsAmount: number,

	fromDocType: 'PM' | 'CN' | 'CT',
	fromDocKey: number,
	fromDocNo: string,
	koCurrencyRate: number,
	koDate: number,

	code: string,
	agent: string,

	docKey: number,
	foreignAmount: number,
	amount: number,
	gainLoss: number,
}
export interface CustomerSalesTransCard {

	code: string,
	date: number,
	agent: string,
	foreignAmount: number,
	amount: number,
	foreignTaxAmount: number,
	taxAmount: number,
	quantity: number,
	itemQuantity: number,
}
export interface CustomerSupplierTradeCard {
	docType: string,
	date: number,
	code: string,
	agent: string,
	foreignAmount: number,
	amount: number,
	foreignTaxAmount?: number,
	taxAmount?: number,
	unappliedAmount?: number,
	firstDeal?: number,
	lastDeal?: number,
}
export interface NetSalesCard {
	date: number,
	foreignAmount: number,
	amount: number,
}
export interface SupplierCard {
	code: string,
	companyName: string,
	companyCategory: string,
	companyCategoryDesc: string,
	agent: string,
	agentDesc: string,
	lat: number,
	lng: number,
}

export interface SupplierPiSdCard {
	docType: 'IV' | 'DN',
	docKey: number,
	docNo: string,
	code: string,
	date: number,
	terms: string,
	dueDate: number,
	agent: string,
	currencyRate: number,
	foreignAmount: number,
	amount: number,
	foreignOsAmount: number,
	osAmount: number,
	lastKoDocDate: number,
}
export interface SupplierKoCard {
	toDocType: 'IV' | 'DN',
	toDocKey: number,
	toDocNo: string,
	lastKoDocDate: number,
	dueDate: number,
	date: number,
	currencyRate: number,
	osAmount: number,
	foreignOsAmount: number,

	fromDocType: 'PM' | 'CN' | 'CT',
	fromDocKey: number,
	fromDocNo: string,
	koCurrencyRate: number,
	koDate: number,

	code: string,
	agent: string,

	docKey: number,
	foreignAmount: number,
	amount: number,
	gainLoss: number,
}
export interface SupplierPurchaseTransCard {

	code: string,
	date: number,
	agent: string,
	foreignAmount: number,
	amount: number,
	foreignTaxAmount: number,
	taxAmount: number,
	quantity: number,
	itemQuantity: number,
}
export interface StockTransCard {

	itemCode: string,
	stockGroup: string,
	date: number,
	quantity: number,
	quantityIn: number,
	quantityOut: number,
	amountIn: number,
	amountOut: number,
	price: number,
	cost: number,
	docType: string,
	docNo: string,
}

export interface CustomerSupplierDbCard {
	code: string,
	companyName: string,
	companyCategory: string,
	companyCategoryDesc: string,
	area: string,
	areaDesc: string,
	creditLimit: number,
	creditTerm: string,
	lastReviewDate: number,
	status: string,
}
export interface ProjectDbCard {
	code: string,
	projectDesc: string,
	projectValue: number,
	projectCost: number,
	status: string,
	startDate: number,
	endDate: number,
	isActive: string,
}
export interface LocationDbCard {
	code: string,
	description: string,
}
export interface AccountDbCard {
	code: string,
	parent: string,
	generation: number,
	accountName: string,
	accountType: string,
	specialAccountType: string,
}
export interface AgentDbCard {
	agent: string,
	agentDesc: string,
}
export interface StockDbCard {
	itemCode: string,
	itemDesc: string,
	stockGroup: string,
	stockGroupDesc: string,
	itemUOM: string,
	minInvLevel: number,
	costingMethod: number,
	leadTime: number,
	isActive: string,
	isDiscontinued: string,
	reorderQty: number,
}
export interface AreaDbCard {
	code: string,
	description: string,
}
export interface CompanyCategoryDbCard {
	code: string,
	description: string,
}

export interface DashboardSales {
	docType: string,
	date: number,
	agent: string,
	agentDesc: string,
	code: string,
	companyName: string,
	amount: number,
	tax: number,
	amountWithTax: number,
	cost: number,
	profit: number,
}

export interface DashboardQuotation {
	docType: string,
	date: number,
	agent: string,
	agentDesc: string,
	code: string,
	companyName: string,
	amount: number,
	tax: number,
	amountWithTax: number,
	transferredAmount: number,
	transferredTax: number,
	transferredAmountWithTax: number,
	outstandingAmount: number,
	outstandingTax: number,
	outstandingAmountWithTax: number,
}

export interface DashboardCashFlow {
	date: number,
	cashInFlow: number,
	cashOutFlow: number,
	cashFlow: number,
}

export interface DashboardCashBalance {
	date: number,
	specialAccountType: string,
	netAmount: number,
}

export interface DashboardApArDue {
	docType: string,
	docNo: number,
	code: string,
	companyName: string,
	date: number,
	dueDate: number,
	agent: string,
	amount: number,
}

export interface DashboardData {
	sales0: number,
	sales1: number,
	sales2: number,
	sales3: number,
	sales4: number,
	sales5: number,
	sales6: number,
	sales7: number,
	todayNumIv: number,
	todayProfit: number,
	ytdProfit: number,
	todayNumQuotation: number,
	todayQuotadion: number,
	ytdQuotation: number,
	customerPayment: number,
	supplierPayment: number,
	todayCashFlow: number,
	ytdCashFlow: number,
	cashOnHand: number,
	cashInBAnk: number,
}

export interface DashboardStockGroupSales {
	docType: string,
	date: number,
	stockGroup: string,
	quantity: number,
	amount: number,
	taxAmount: number,
}

export interface DashboardCustomerSupplierDues {
	docType: string,
	docNo: string,
	code: string,
	date: number,
	dueDate: number,
	agent: string,
	amount: number,
}

export interface DashboardDataPackage {
	data: DashboardData | null,
	stockGroupSales: DashboardStockGroupSales[],
	customerDues: DashboardCustomerSupplierDues[],
	supplierDues: DashboardCustomerSupplierDues[],
}

export interface CardDb {
	salesCards: SalesDetailCard[],
	creditNoteCards: SalesDetailCard[],
	debitNoteCards: SalesDetailCard[],
	purchaseCards: PurchaseDetailCard[],
	quotationCards: QuotationDetailCard[],
	stockBalanceCards: StockBalanceCard[],
	transactionCards: TransactionCard[],
	currencySymbol: string,
	accountStartingDate: Moment,
	numDecimal: number,
	openingStockAmount: number,

	customerCards: CustomerCard[],
	customerIvDnCards: CustomerIvDnCard[],
	customerKoCards: CustomerKoCard[],
	customerSalesTransCards: CustomerSalesTransCard[],
	customerTradeCards: CustomerSupplierTradeCard[],
	customerNetSalesCards: NetSalesCard[],

	supplierCards: SupplierCard[],
	supplierPiSdCards: SupplierPiSdCard[],
	supplierKoCards: SupplierKoCard[],
	supplierPurchaseTransCards: SupplierPurchaseTransCard[],
	supplierTradeCards: CustomerSupplierTradeCard[],
	supplierNetCogsCards: NetSalesCard[],

	stockTransCards: StockTransCard[],

	supplierDbCards: CustomerSupplierDbCard[],
	customerDbCards: CustomerSupplierDbCard[],
	agentDbCards: AgentDbCard[],
	stockDbCards: StockDbCard[],

	dashboardDataPackage: DashboardDataPackage | null,
}

export interface UpdateFilterSubmmision {
	allAgents: string[],
	allStockGroups: string[],
	allStockItems: string[],
}

export interface FilterQuery {
	from: FilterableCardName,
	query: string,
}

export interface LoadGdbSubmission {
	accountId: string,
	gdbid: string,
}
export interface GroupedSumDic {
	[key: string]: number,
}
export interface SortedAndOtherGrouped {
	sortedGroupNames: string[],
	sortedGroupSums: number[],
	dic: GroupedDataDic<any>,
	otherKeys?: string[],
}
export interface GroupNameSumPair {
	groupName: string;
	sum: number;
}

export interface AgentWithGroupedStockGroup {
	agent: string,
	agentDesc: string,
	total: number,
	stockGroupNames: string[],
	stockGroupSums: number[],
}
export interface CustomerWithGroupedStockGroup {
	customerDesc: string,
	total: number,
	stockGroupNames: string[],
	stockGroupSums: number[],
}


export interface SetPermmissionSubmission extends AccountRelatedSubmission {
	email: string,
	admin?: boolean,
	dashboard?: boolean,
	sales?: boolean,
	purchases?: boolean,
	quotations?: boolean,
	transactions?: boolean,
	suppliers?: boolean,
	customers?: boolean,
	payroll?: boolean,
	stocks?: boolean,
	forecastRead?: boolean,
	forecastWrite?: boolean,
}

export type PermissionGroupLoadProgress = {
	[T in PermissionsGroup]: GdbLoadProgress
};

export type CardLoadProgress = {
	[T in FilterableCardName]?: GdbLoadProgress
};

export interface AddRemoveAccountUserSubmission extends AccountRelatedSubmission {
	email: string,
	remove?: boolean,
}
export interface SaveForecastDocSubmission extends AccountRelatedSubmission {
	forecast: ForecastDoc,
}
export interface RemoveForecastDocSubmission extends AccountRelatedSubmission {
	docId: string,
}

export interface PermissionsData {
	[email: string]: AccountUserPermission,
}
export interface AccountProperties {
	currencySymbol: string,
	accountStartingDate: Moment,
	numDecimal: number,
	openingStockAmount: number,
}

export interface AccountUserPermission extends SetPermmissionSubmission {
	uid: string | null,
	displayName?: string,
}


export interface QuotationGroupedByAgent {
	agentDesc: string,
	totalAmount: number,
	totalOutstandingAmount: number,
	convertedQuotation: number,
	conversionRate: number,
}
export interface DocInBatch {
	_id: string,
	_removed?: boolean,
	_version?: number,
	[key: string]: any;
}

export interface CollectionBatch {
	docId: string,
	createdAt: number,
	docs: Dictionary<DocInBatch>,
}

export interface SettingsSubmission {
	includeTax?: boolean,
	includeCreditNote?: boolean,
	includeDebitNote?: boolean,
	showProfit?: boolean,
	highlightAccount?: boolean,
	switchAmountQuantity?: string,
}

export type FilterableCardName = 'sales' | 'purchases' | 'quotations'
	| 'salesOrder' | 'transactions' | 'cashflow' | 'stockBalances' | 'creditNotes' | 'debitNotes'
	| 'purchaseReturn'
	| 'customers' | 'suppliers' | 'payroll' | 'stockTrans' | 'stockUtdBalance' | 'stockPurchaseOrder' | 'stockAging' | 'stockAvailability' | 'stockSales' | 'stockPurchase' | 'stockMovement' | 'stockReorderAdvise' | 'stockBatchExpiry' | 'purchaseOrder' | 'purchaseRequest' | 'projectCashFlow' | 'projectStockGroup' | 'projectCosting' | 'customerAnalysis' | 'supplierAnalysis'
	| 'system' | 'globalAgents' | 'globalStocks' | 'globalAcc' | 'globalCustomers' | 'globalSuppliers' | 'globalProjects' | 'globalLocations' | 'globalArea' | 'globalCompanyCategory'
	| 'customerIvDns' | 'customerKos' | 'customerSalesTrans' | 'customerTrades' | 'customerNetSales' | 'supplierKos' | 'supplierPiSds' | 'supplierPurchaseTrans' | 'supplierTrades' | 'supplierNetCogs'
	| 'dashboardData' | 'dashboardStockGroupSales' | 'dashboardCustomersDue' | 'dashboardSuppliersDue' | 'dashboardSales' | 'dashboardQuotation' | 'dashboardCashFlow' | 'dashboardCashBalance' | 'dashboardApArDue' | 'dashboardArCustomer' | 'diyDailyProjectSales' | 'diyStockPackingBudget' | 'diySalesSummary' | 'diyMaintainStockItem' | 'diySupportRenewal' | 'closingStockBalance' ;
export const permissionsGroups = ['global', 'dashboard', 'sales', 'purchases',
	'quotations', 'transactions', 'suppliers', 'customers', 'payroll', 'stocks', 'forecastRead', 'forecastWrite'] as const;
export type PermissionsGroup = typeof permissionsGroups[number];
export interface UpdateGdbSystemSubmission {
	currencySymbol: string,
	accountStartingDate: Moment,
	numDecimal: number,
}

export interface FilterDbSubmission {
	globalAgents?: AgentDbCard[],
	globalStocks?: StockDbCard[],
	globalCustomers?: CustomerSupplierDbCard[],
	globalSuppliers?: CustomerSupplierDbCard[],
	globalProjects?: ProjectDbCard[],
	globalLocations?: LocationDbCard[],
	globalAcc?: AccountDbCard[],
	globalArea?: AreaDbCard[],
	globalCompanyCategory?: CompanyCategoryDbCard[],
}
export interface FilterLevel {
	name: string,
	filterType: 'string' | 'number',
}

export interface ConditionType {
	isAll: boolean;
	stringList?: string[],
	numberOperators?: NumberRangeOperator;
	filterType?: 'string' | 'number',
}

export type NumberRangeOperator = [
	['>' | '>=' | '=', number],
	['<' | '<=', number]?,
];

export interface DimensionMap extends ValueText {
	text: string,
	value: string[] | NumberRangeOperator |
		(() => Promise<string[]>) | (() => Promise<NumberRangeOperator>),
	filterType: 'string' | 'number',
	filterKey: string,
}

export interface DimensionData extends DimensionMap {
	value: string[] | NumberRangeOperator,
	sum: number,
	subDimension?: DimensionData[];
}


export interface ForecastDoc {
	docId: string,
	title: string,
	dateRangeSet: boolean,
	dateRange: [number, number],
	isNew: boolean,
	createdBy: string,
	createdAt: number,
	lastEditedBy: string,
	lastEditedAt: number,
	sales: ForecastDocSale,
	grossProfit: ForecastDocGrossProfit,
	fixedExpenses: ForecastDocFixedEnpenses,
	variableExpenses: ForecastOneToMany,
}

export interface ForecastDocSale {
	dateSet: boolean,
	dateRangeSet: boolean,
	dateRange: [number, number],
	date: number,
	type: 'copy' | 'grow' | 'flat',
	resultType: 'average' | 'month' | 'manual',
	syncAt: number,
	syncResult: number[],
	result: number[],
	resultSet: boolean,
	growth: number,
	growthSet: boolean,
	isFirstMonthFixed: boolean,
	isExponential: boolean,
}

export interface ForecastDocGrossProfit {
	dateSet: boolean,
	dateRangeSet: boolean,
	dateRange: [number, number],
	date: number,
	type: 'average' | 'month' | 'manual',
	growth: number,
	syncAt: number,
	syncResult: number,
	resultSet: boolean,
	result: number[],
}
export interface ForecastDocFixedEnpenses {
	type: 'average' | 'copy' | 'manual',
	dateRangeSet: boolean,
	dateRange: [number, number],
	growth: number,
	syncAt: number,
	syncResult: number[],
	resultSet: boolean,
	result: number[],
}
export interface ForecastOneToMany {
	sample: number,
	resultSet: boolean,
	result: number[],
}


export interface AgentSalesMetrics {
	year: number;
	salesAmount: number;
	salesDocQty: number;
	averageSalesPerCase: number;
	averageSalesCase: number;
	averageSales: number;
	averageSalesCycle: number;
	quotationAmount: number;
	quotationDocQty: number;
	averageQuotationCase: number;
	winRate: number;
}
export interface IndividualAgentCustomerSalesMetrics {
	year: number;
	salesAmount: number;
	salesDocQty: number;
	averageSalesPerCase: number;
	averageSalesCase: number;
	averageSales: number;
	averageSalesCycle: number;
	salesRanking: number;
	salesMargin: number;
	revenueContribution: number;
	grossProfitContribution: number;
	caseContribution: number;
}
export interface StockSalesMetrics {
	salesAmount: number;
	salesQuantity: number;
	stockGroupSales: number;
	productSales: number;
	averageUnitPerDeal: number;
	averageUnitPerMonth: number;
	bestMonth: string;
	worstMonth: string;
}
export interface FinancialPerformance {
	year: number;
	revenueGrowth: number;
	grossMargin: number;
	netMargin: number;
	cashRatio: number;
	currentRatio: number;
	debtRatio: number;
	returnOnEquity: number;
	leverageRatio: number;
	fixedAssetToNetRatio: number;
	efficiencyRatio: number;
	currentLiabilitiesToNetWorth: number;
	workingCapitalToTotalAsset: number;
	inventoryTurnover: number;
	accountReceivableTurnover: number,
	accountPayableTurnover: number,
	totalAssetTurnover: number;
}
export interface StockUtdBalance {
	itemCode: string;
	itemDesc: string;
	stockGroup: string;
	stockGroupDesc: string;
	gsm: number;
	size: string;
	quantity: number;
	poQuantity: number;
	brand: string;
	remark1: string;
	remark2: string;
}
export interface StockPurchaseOrder {
	docNo: string;
	itemCode: string;
	deliveryDate: number;
	quantity: number;
	remark1: string;
}
export interface PurchaseRequestOrder {
	date: number;
	docNo: string;
	amount: number;
	agent: string;
	stockGroup: string;
	itemCode: string;
	code: string;
	quantity: number;
	discount: number;
	tax: number;
	transferredAmt: number;
	osQuantity: number;
}
export interface ProjectCashFlow {
	month: number;
	year: number;
	projectCode: string;
	otherStock: number;
	customerDebit: number;
	customerInvoice: number;
	customerPayment: number;
	customerContra: number;
	customerCredit: number;
	customerRefund: number;
	stockIssue: number;
	stockReturn: number;
	expenses: number;
	subCon: number;
	labour: number;
}
export interface ProjectStockGroup {
	month: number;
	year: number;
	projectCode: string;
	stockGroup: string;
	cost: number;
}
export interface CashFlow {
	postDate: number;
	code: string;
	journal: string;
	account: string;
	fromTo: string;
	purpose: string;
	cashIn: number;
	cashOut: number;
}

export interface ProjectCosting {
	month: number;
	year: number;
	projectCode: string;
	costType: string;
	cost: number;
}


