import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import { Component } from 'vue-property-decorator';
import moment from 'moment';
import vuePositionSticky from 'vue-position-sticky';
import VueAnalytics from 'vue-analytics';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';

import '@/scss/global.scss';

// import fdw from '@/worker/fdw';

(window as any).moment = moment;
Vue.use(PrimeVue, {ripple: true});
Vue.use(BootstrapVue);
Vue.use(ToastService);
Vue.use(vuePositionSticky);
Vue.use(VueAnalytics, {
	// id: ['UA-155238521-1', 'UA-155238521-2'],
	id: process.env.NODE_ENV === 'development' ? ['UA-155238521-1'] : ['UA-155238521-2'],
	debug: {
		enabled: false,
		sendHitTask: true,
	},

});

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');

Component.registerHooks([
	'beforeRouteEnter',
	'beforeRouteLeave',
	'beforeRouteUpdate',
]);

(window as any).version = '1.0.3';
