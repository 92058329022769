import firebase from 'firebase/app';
import fb from '@/store/gobi-firestore';

const db = fb.db;

interface PromiseData {
	resolve: (docs: any[]) => void,
	reject: (error: Error) => void,
	orderBy?: string,
	order?: 'asc' | 'desc',
}

/*
WARNING: when using get() with 'orderBy' arg, the instance will use the 1st
'orderBy' as query, this will result the documents with undefined
field of 'orderBy' never get loaded.
*/
export class FirestoreColFakeGet {
	protected _path!: string;
	protected _ref!: firebase.firestore.CollectionReference;
	protected _unsubscribe: (() => void) | null = null;

	protected _docs: firebase.firestore.QuerySnapshot | null = null;
	protected _error: Error | null = null;

	protected _unresolved: PromiseData[] = [];

	protected _datas: any[] | null = null;
	protected _initialOrderBy: string | null = null;
	protected _initialOrder: 'asc' | 'desc' = 'asc';

	private _beforeLoad: number = 0;

	constructor(path: string) {
		this._path = path;
		this._ref = db.collection(path);

	}

	public get(orderBy?: string, order: 'asc' | 'desc' = 'asc') {
		this._beforeLoad = Date.now();
		if (!this._unsubscribe) {
			if (orderBy) {
				this._initialOrderBy = orderBy;
				this._initialOrder = order;
			}
			const ref = (orderBy) ? this._ref.orderBy(orderBy, order) : this._ref;
			this._unsubscribe = ref.onSnapshot(
				{ includeMetadataChanges: true },
				(docs) => {
					if (!docs.metadata.fromCache) {
						this._docs = docs;
						this._error = null;

						const before = Date.now();
						this._datas = [];
						let i = 0;
						while (i < docs.docs.length) {
							const doc = docs.docs[i];
							const data = doc.data();
							this._datas!.push(data);
							i++;
						}

						while (this._unresolved.length > 0) {
							this._resolve(this._unresolved.shift()!);
						}
						// console.log('fake get process', `${Date.now() - before}ms`);
					}
				}, (error) => {
					this._docs = null;
					this._datas = null;
					this._error = error;
					while (this._unresolved.length > 0) {
						this._unresolved.shift()!.reject(error);
					}
				});
		}
		if (this._docs) {
			return new Promise(
				(resolve: (docs: any[]) => void,
					reject: (error: Error) => void) => {
					this._resolve({ resolve, reject, orderBy, order });
				});
		} else if (this._error) {
			return new Promise(
				(resolve: (docs: any[]) => void,
					reject: (error: Error) => void) => {
					reject(this._error!);
				});
		} else {
			return new Promise(
				(resolve: (docs: any[]) => void,
					reject: (error: Error) => void) => {
					this._unresolved.push({ resolve, reject, orderBy, order });
				});
		}
	}

	public dispose() {
		if (this._unsubscribe) { this._unsubscribe(); }
		while (this._unresolved.length > 0) {
			this._unresolved.shift()!.reject(new Error('Canceled'));
		}
	}

	protected _resolve(promise: PromiseData) {

		// console.log('fake load', Date.now() - this._beforeLoad, this._path);
		const { orderBy, order, resolve } = promise;
		if (!this._datas) {
			resolve([]);
			return;
		}
		const result = this._datas.slice();
		const asc = (order === 'asc') ? 1 : -1;
		if (orderBy &&
			(orderBy !== this._initialOrderBy || order !== this._initialOrder)) {
			result.sort((a, b) => {
				if (a[orderBy] < b[orderBy]) {
					return -asc;
				}
				if (a[orderBy] < b[orderBy]) {
					return asc;
				}
				return 0;
			});
		}
		resolve(result);
	}
}
