
import fb from '@/store/gobi-firestore';

const functions = fb.functions;
const auth = fb.auth;

export async function signInAsLiveDemo() {
	const result = await functions
		.httpsCallable('getLiveDemoToken')();
	const token = result.data;

	await auth.signInWithCustomToken(token);
}
