
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';

import router, { nonUserRelatedPath } from '@/router';
import userx from '@/store/modules/userx';
import { LoginStatus } from '@/store/models.def';
import { navigationValidation } from '@/router';
import ProgressToaster from './components/toaster/ProgressToaster.vue';
import LogoutModal from '@/components/modals/LogoutModal.vue';
import { wait } from './util/wait';
@Component({
  components: {
    Loading,
    ProgressToaster,
    LogoutModal,
  },
})
export default class App extends Vue {
  get loginStatus() {
    return userx.loginStatus;
  }
  get needCompleteRegistration() {
    return userx.needCompleteRegistration;
  }
  get userReady() {
    if (userx.loginStatus === 'loading') {
      return false;
    }
    if (userx.loginStatus === 'logged out') {
      return true;
    }
    if (userx.needCompleteRegistration === undefined) {
      return false;
    }
    return true;
  }
  get emailVerified() {
    return userx.user && userx.user.emailVerified;
  }
  get emailVerificationSent() {
    return userx.emailVerificationSent;
  }
  get nonUserRelated() {
    return nonUserRelatedPath.includes(this.$route.path);
  }
  @Watch('loginStatus')
  public onLoginStatusChanged(val: LoginStatus, oldVal: LoginStatus) {
    this.navigationValidation();
  }
  @Watch('needCompleteRegistration')
  public onNeedCompleteRegistrationChanged(
    val: boolean | undefined,
    oldVal: boolean | undefined,
  ) {
    this.navigationValidation();
  }

  public navigationValidation() {
    const validation = navigationValidation(this.$route);
    if (!validation.success && validation.newRoute) {
      if (validation.newRoute.replace) {
        router.replace(validation.newRoute);
      } else {
        router.push(validation.newRoute);
      }
    }
  }

	public sendEmailVerification(e: any) {
		userx.sendEmailVerification();
	}
	// public created() {

  //   const now = new Date();

  //   const getExpiry = localStorage.getItem('notifyRotateSceenExpiry');
  //   const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  //   if (isMobile) {
  //     if (getExpiry) {
  //       if (
  //         now.valueOf() - JSON.parse(getExpiry).valueOf() >
  //         1000 * 60 * 60 * 24
  //       ) {
  //         localStorage.setItem(
  //           'notifyRotateSceenExpiry',
  //           JSON.stringify(now.valueOf()),
  //         );
  //         alert(
  //           'We recommend rotate your screen to landscape to improve viewing experience.',
  //         );
  //       }
  //     } else {
  //       localStorage.setItem(
  //         'notifyRotateSceenExpiry',
  //         JSON.stringify(now.valueOf()),
  //       );
  //       alert(
  //         'We recommend rotate your screen to landscape to improve viewing experience.',
  //       );
  //     }
  //   }
  // }
}
