import {
	getModule,
	Module,
	VuexModule,
	Mutation,
	Action,
  } from 'vuex-module-decorators';
import store from '@/store';
import { ProcessState, ToastData } from '@/store/models.def';

(window as any).store = store;
@Module({
	namespaced: true,
	name: 'progressToastx',
	store,
	dynamic: true,
  })
  class ProgressToastsModule extends VuexModule {

	private _count = 0;
	private _toasts: ToastData[] = [];

	public get toasts(): ToastData[] {
		return this._toasts;
	}

	@Mutation
	public m_add(data: ToastData) {
		this._toasts.push(data);
		this._count++;
	}
	@Mutation
	public m_update(data: ToastData) {
		this._toasts = this._toasts.map((el) => {
			if ( el.id === data.id ) { return data; } else { return el; }
		});
	}
	@Mutation
	public m_remove(id: string) {
		const index = this._toasts.findIndex((el) => el.id === id);
		this._toasts.splice(index, 1);
	}
	@Action
	public add(target: {state?: string, title?: string, destroyWhenHidden?: boolean, variant?: string}) {
		const title = target.title || '';
		const state = target.state || '';
		const destroyWhenHidden = (target.destroyWhenHidden === undefined) ? false : target.destroyWhenHidden;
		const id = `progress-toast-${this._count + 1}`;
		const data: ToastData = {id, title, state, destroyWhenHidden, variant: target.variant};
		this.m_add(data);
		return id;
	}
	@Action
	public update(target: {id: string, title?: string, state?: string, destroyWhenHidden?: boolean, variant?: string}) {
		const {id, title, state, destroyWhenHidden, variant } = target;
		const oldData = this._toasts.find((el) => el.id === id);
		if (oldData) {
			const data: ToastData = {id, title: title!, state: state!, destroyWhenHidden: destroyWhenHidden!, variant};
			if (title === undefined) { data.title = oldData.title; }
			if (state === undefined) { data.state = oldData.state; }
			if (destroyWhenHidden === undefined) { data.destroyWhenHidden = oldData.destroyWhenHidden; }
			this.m_update(data);
		} else {
			console.error('toast ID not found');
		}
		return id;
	}
	@Action
	public done(target: {id?: string, state?: string, title?: string}) {
		const id = target.id;
		const state = target.state || 'Done.';
		target.state = '<i class="fa fa-check-circle text-success"></i> ' + state;
		if (!target.id) {
			this.add({...target, destroyWhenHidden: true, variant: 'success'});
		} else {
			this.update({...target, id: id!, destroyWhenHidden: true, variant: 'success'});
		}
	}
	@Action
	public error(target: {id?: string, state?: string, title?: string}) {
		const id = target.id;
		const state = target.state || 'Failed.';
		target.state = '<i class="fa fa-times-circle text-danger"></i> ' + state;
		if (!target.id) {
			this.add({...target, destroyWhenHidden: true, variant: 'danger'});
		} else {
			this.update({...target, id: id!, destroyWhenHidden: true, variant: 'danger'});
		}
	}
	@Action
	public remove(id: string) {
		this.m_remove(id);
	}


  }
export default getModule(ProgressToastsModule);
