import {
	getModule,
	Module,
	VuexModule,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import store from '@/store';
import userx from '@/store/modules/userx';
import {
	AccountData, AccountMinimumData, AddRemoveAccountUserSubmission,
	SetPermmissionSubmission, PermissionsData, ForecastDoc, SaveForecastDocSubmission, RemoveForecastDocSubmission,
} from '@/store/models.def';
import accountz from '../accountz';

@Module({
	namespaced: true,
	name: 'accountx',
	store,
	dynamic: true,
})
class CompanyAccountModule extends VuexModule {
	public setCurrentAccountId: string | null = null;
	public fullAccountData: AccountData | null = null;

	public unexistent: boolean = false;
	public unauthorized: boolean = false;
	public forecastUnauthorized: boolean = false;
	public needReport: boolean = false;

	public currentAccountPermissions: PermissionsData = {};
	public allForecasts: ForecastDoc[] | null = null;

	public get numAdmins(): number {
		let num = 0;
		for (const email in this.currentAccountPermissions) {
			if (this.currentAccountPermissions.hasOwnProperty(email)) {
				const user = this.currentAccountPermissions[email];
				if (user.admin) {
					num++;
				}
			}
		}
		return num;
	}

	public get myPermissions(): SetPermmissionSubmission | null {
		if (!userx.user) {
			return null;
		}
		const email = userx.user.email;
		const accountId = this.currentAccountId || '';
		if (userx.supportLvl !== null && userx.supportLvl > 1) {
			return {
				email, accountId,
				admin: true, dashboard: true, sales: true, purchases: true, quotations: true, transactions: true,
				customers: true, suppliers: true, stocks: true, payroll: true,
				forecastRead: true, forecastWrite: true,
			};
		}
		if (!this.currentAccountPermissions || !userx.user || !this.currentAccountPermissions[userx.user.email]) {
			return null;
		}
		return this.currentAccountPermissions[userx.user.email];
	}

	public get lastVisitedAccount(): 'loading' | string | null {
		return userx.lastVisitedAccount;
	}
	public get allAccounts() {
		return userx.myAccounts;
	}

	public get currentAccountId(): string | null {
		if (this.setCurrentAccountId) {
			return this.setCurrentAccountId;
		}
		if (!this.allAccounts) { return 'loading'; }
		if (this.lastVisitedAccount) {
			return userx.lastVisitedAccount; // might be 'loading'
		}
		if (this.allAccounts.length === 0) { return null; }
		return this.allAccounts[this.allAccounts.length - 1].accountId;
	}

	public get minimumAccountData(): AccountMinimumData | null {
		if (!userx.myAccountsDic || !this.currentAccountId || this.currentAccountId === 'loading') {
			return null;
		}
		return userx.myAccountsDic[this.currentAccountId] || null;
	}
	public get currentAccount(): AccountData | AccountMinimumData | null {
		if (!this.currentAccountId || this.currentAccountId === 'loading') {
			return null;
		}
		return this.fullAccountData || this.minimumAccountData;
	}

	@Mutation
	public m_updateAccount(account: AccountData | null) {
		this.fullAccountData = account;
		this.unexistent = false;
		this.unauthorized = false;
	}
	@Mutation
	public m_updateAccountId(accountId: string | null) {
		if (this.fullAccountData && this.fullAccountData.accountId !== accountId) {
			this.fullAccountData = null;
			this.needReport = false;
			this.unexistent = false;
			this.unauthorized = false;
		}
		this.setCurrentAccountId = accountId;
	}
	@Mutation
	public m_isUnauthorized() {
		this.unauthorized = true;
	}
	@Mutation
	public m_unauthorizedForecast() {
		this.forecastUnauthorized = true;
	}
	@Mutation
	public m_isUnexisistent() {
		this.unexistent = true;
	}
	@Mutation
	public m_isShowingReport() {
		this.needReport = true;
	}
	@Mutation
	public m_updateCurrentAccountPermissions(permissions: PermissionsData) {
		this.currentAccountPermissions = permissions;
	}
	@Mutation
	public m_updateAllForecasts(forecasts: ForecastDoc[] | null) {
		this.allForecasts = forecasts;
	}

	@Action
	public updateAccount(account: AccountData | null) {
		this.m_updateAccount(account);
	}
	@Action
	public updateAccountId(accountId: string | null) {
		this.m_updateAccountId(accountId);
	}
	@Action
	public isUnauthorized() {
		this.m_isUnauthorized();
	}
	@Action
	public isUnexisistent() {
		this.m_isUnexisistent();
	}
	@Action
	public isShowingReport() {
		this.m_isShowingReport();
	}

	@Action public updateCurrentAccountPermissions(permissions: PermissionsData) {
		this.m_updateCurrentAccountPermissions(permissions);
	}
	@Action
	public async addRemoveAccountUser(submission: AddRemoveAccountUserSubmission) {
		const result = await accountz.addRemoveAccountUser(submission);
		return result;
	}
	@Action
	public async setPermissions(submission: SetPermmissionSubmission) {
		const result = await accountz.setPermissions(submission);
		return result;
	}
	@Action
	public unauthorizedForecast() {
		this.m_unauthorizedForecast();
	}
	@Action
	public updateAllForecasts(forecasts: ForecastDoc[] | null) {
		this.m_updateAllForecasts(forecasts);
	}
	@Action
	public async saveForecast(submission: SaveForecastDocSubmission) {
		return await accountz.saveForecast(submission);
	}
	@Action
	public async removeForecast(submission: RemoveForecastDocSubmission) {
		return await accountz.removeForecast(submission);
	}
}


export default getModule(CompanyAccountModule);
const a = accountz;
