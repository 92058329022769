import Vue from 'vue';
import Router, { Route } from 'vue-router';

import userx from '@/store/modules/userx';
import { Dictionary } from 'vue-router/types/router';
import globalx from './store/modules/globalx';
import { wait } from '@/util/wait';
import accountx from './store/modules/accountx';
// import { signInAsLiveDemo } from '@/helpers/signInAsLiveDemo';

export interface NavigationValidation {
	success: boolean,
	newRoute?: { path: string, query?: any, replace?: boolean },
}

Vue.use(Router);

let currentAccountId: string = '';

// Exclude access to all modules
const excludeAllAccountIdList: string[] = ['AhhvdeCWa1iJJqPpgq26'];

// Exclude dashboard module
const excludeDashboardModuleAccountIds: string[] = [];

// Exclude sales module
const excludeSalesModuleAccountIds: string[] = [];

// Exclude agent module
const excludeAgentModuleAccountIds: string[] = ['Cv7yI26SiPZ07zeUcWQQ'];

// Exclude stock module
const excludeStockModuleAccountIds: string[] = [];

// Exclude customer module
const excludeCustomerModuleAccountIds: string[] = ['Cv7yI26SiPZ07zeUcWQQ'];

// Exclude purchase module
const excludePurchaseModuleAccountIds: string[] = ['Cv7yI26SiPZ07zeUcWQQ'];

// Exclude supplier module
const excludeSupplierModuleAccountIds: string[] = [];

// Exclude business performance module
const excludeBPModuleAccountIds: string[] = ['Cv7yI26SiPZ07zeUcWQQ'];

// Exclude forecast module
const excludeForecastModuleAccountIds: string[] = ['Cv7yI26SiPZ07zeUcWQQ'];

// Include project tracker module
const involveProjectTrackerList: string[] = ['Eth0Uo5RMQobxFEltAA3', 'lidNP3pv5YH88BLkA9JF', 'BtbOPKLDiFhoSqCsxdSb'];

const isExcludeAllModules =
	excludeAllAccountIdList.includes(currentAccountId);

const router = new Router({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			redirect: '/account',
			component: () => import('./views/Portal.vue'),
			children: [
				{
					path: '/forgot-password',
					name: 'forgot-password',
					component: () => import('./components/portal/ForgotPassword.vue'),
				},
				{
					path: '/register',
					name: 'register',
					component: () => import('./components/portal/Register.vue'),
				},
				{
					path: '/login',
					name: 'login',
					component: () => import('./components/portal/Login.vue'),
				},
			],
		},
		{
			path: '/account',
			name: 'Account',
			component: () => import('@/components/containers/DefaultContainer.vue'),
		},
		{
			path: '/account/:accountId',
			redirect: '/account/:accountId/dashboard',
			component: () => import('@/components/containers/DefaultContainer.vue'),
			children: [
				{
					path: '',
					component: () => import('@/components/accounts/AccountContainer.vue'),
					children: [
						{
							path: 'dashboard',
							name: 'Dashboard',
							component: () => import('@/components/reports-v2/pages/dashboard/Dashboard.vue'),
						}, {
							path: 'custom',
							name: 'myFavorite',
							component: () => import('@/components/reports-v2/pages/dashboard/MyFavorite.vue'),
						}, {
							path: 'dealer',
							name: 'Dealer',
							component: () => import('@/components/reports-v2/pages/dealer/Dealer.vue'),
						},
						{
							path: 'sales-overview',
							name: 'Sales Overview',
							component: () => import('@/components/reports-v2/pages/sales/SalesOverview.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeSalesModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'sales-progress',
							name: 'Sales Progress',
							component: () => import('@/components/reports-v2/pages/sales/SalesProgress.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeSalesModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'sales-growth',
							name: 'Sales Growth',
							component: () => import('@/components/reports-v2/pages/sales/SalesGrowth.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeSalesModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'sales-analysis',
							name: 'Sales Analysis',
							component: () => import('@/components/reports-v2/pages/sales/SalesAnalysis.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeSalesModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'sales-delivery',
							name: 'Sales Delivery',
							component: () => import('@/components/reports-v2/pages/sales/SalesDelivery.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeSalesModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'agent-overview',
							name: 'Agent Overview',
							component: () => import('@/components/reports-v2/pages/agent/AgentOverview.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeAgentModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'stock-overview',
							name: 'Stock Overview',
							component: () => import('@/components/reports-v2/pages/stock/StockOverview.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeStockModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'customer-overview',
							name: 'Customer Overview',
							component: () => import('@/components/reports-v2/pages/customer/CustomerOverview.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeCustomerModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'purchase-overview',
							name: 'Purchase Overview',
							component: () => import('@/components/reports-v2/pages/purchase/PurchaseOverview.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludePurchaseModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'purchase-analysis',
							name: 'Purchase Analysis',
							component: () => import('@/components/reports-v2/pages/purchase/PurchaseAnalysis.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludePurchaseModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'supplier-overview',
							name: 'Supplier Overview',
							component: () => import('@/components/reports-v2/pages/supplier/SupplierOverview.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeSupplierModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'supplier-analysis',
							name: 'Supplier Analysis',
							component: () => import('@/components/reports-v2/pages/supplier/SupplierAnalysis.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeSupplierModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'profit-loss',
							name: 'Profit and Loss',
							component: () => import('@/components/reports-v2/pages/gl/ProfitAndLoss.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeBPModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'balance-sheet',
							name: 'Balance Sheet',
							component: () => import('@/components/reports-v2/pages/gl/BalanceSheet.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeBPModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'cash-flow',
							name: 'Cash Flow',
							component: () => import('@/components/reports-v2/pages/gl/CashFlow.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeBPModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'financial-ratio',
							name: 'Financial Ratio',
							component: () => import('@/components/reports-v2/pages/gl/FinancialRatio.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeBPModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'stock-analysis',
							name: 'Stock',
							redirect: 'stock-analysis/1',
							component: () => import('@/components/reports-v2/pages/stock/Stock.vue'),
							children: [
								{
									path: '1',
									component: () => import('@/components/reports-v2/pages/stock/Page1.vue'),
								},
								{
									path: '2',
									component: () => import('@/components/reports-v2/pages/stock/Page2.vue'),
								},
							],
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeStockModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'forecast',
							name: 'Forecast',
							component: () => import('@/views/Forecast.vue'),
							children: [
								{
									path: ':docId',
									name: 'ForecastEdit',
								},
							],
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeForecastModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'credit-limit',
							name: 'Credit Limit',
							component: () => import('@/components/reports-v2/pages/recommendation/CreditLimit.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (currentAccountId !== null
									&& currentAccountId === '3QcHw8jy23S7E1NWbcRL') {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'system-reorder',
							name: 'System Reorder',
							component: () => import('@/components/reports-v2/pages/recommendation/Reorder.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (currentAccountId !== null
									&& currentAccountId === '3QcHw8jy23S7E1NWbcRL') {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'manual-reorder',
							name: 'Manual Reorder',
							component: () => import('@/components/reports-v2/pages/recommendation/ManualReorder.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (currentAccountId !== null
									&& currentAccountId === '3QcHw8jy23S7E1NWbcRL') {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'admin',
							name: 'Admin',
							component: () => import('@/views/Admin.vue'),
						},
						{
							path: 'customer-analysis',
							name: 'Customer Analysis',
							redirect: 'customer-analysis/1',
							component: () => import('@/components/reports-v2/pages/customer/Customer.vue'),
							children: [
								{
									path: '1',
									component: () => import('@/components/reports-v2/pages/customer/Page1.vue'),
								},
								{
									path: '2',
									component: () => import('@/components/reports-v2/pages/customer/Page2.vue'),
								},
							],
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									!excludeCustomerModuleAccountIds.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'eg-stock-availability',
							name: 'Stock Availability',
							component: () => import('@/components/reports-v2/pages/stock/evergreen/EgStockAvailability.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (currentAccountId !== null
								) {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'wls-customer-aging',
							name: 'Customer Aging',
							component: () => import('@/components/reports-v2/pages/customer/wls/WlsCustomerAging.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (currentAccountId !== null
									&& currentAccountId === '3QcHw8jy23S7E1NWbcRL') {
									next();
								} else {
									next(false);
								}
							},
						},
						{
							path: 'project-tracker',
							name: 'Project Tracker',
							component: () => import('@/components/reports-v2/pages/project/ProjectTracker.vue'),
							beforeEnter: (to, from, next) => {
								window.scrollTo(0, 0);
								if (!isExcludeAllModules &&
									involveProjectTrackerList.includes(currentAccountId)) {
									next();
								} else {
									next(false);
								}
							},
						},
					],
				},
			],
		},
		{
			path: '/all-accounts',
			component: () => import('@/components/containers/DefaultContainer.vue'),
			children: [
				{
					path: '',
					name: 'All Accounts',
					component: () => import('@/views/AllAccounts.vue'),
				},
			],
		},
		{
			path: '/profile',
			component: () => import('@/components/containers/DefaultContainer.vue'),
			children: [
				{
					path: '',
					name: 'Profile',
					component: () => import('@/views/Profile.vue'),
				},
			],
		},
		{
			path: '/reset-password',
			component: () => import('@/components/containers/DefaultContainer.vue'),
			children: [
				{
					path: '',
					name: 'Reset Password',
					component: () => import('@/views/Profile.vue'),
				},
			],
		},
		{
			path: '/plan-details',
			component: () => import('@/components/containers/DefaultContainer.vue'),
			children: [
				{
					path: '',
					name: 'Plan Detail',
					component: () => import('@/components/user/MyPlan.vue'),
				},
			],

		},
		{
			path: '/portal',
			name: 'Portal',
			component: () => import('./views/Portal.vue'),
		},


		{
			path: '/complete-registration',
			component: () => import('./views/Auth.vue'),
			children: [
				{
					path: '/',
					name: 'Complete Registration',
					component: () => import('./components/auth/CompleteRegistration.vue'),
				},
			],
		},
		{
			path: '/auth',
			component: () => import('./views/Auth.vue'),
			children: [
				{
					path: '/',
					name: 'Auth Actions',
					component: () => import('./components/auth/AuthAction.vue'),
				},
			],
		},
		{
			path: '*',
			component: () => import('@/components/containers/DefaultContainer.vue'),
			children: [
				{
					path: '/',
					name: '404',
					component: () => import('@/components/containers/PageError.vue'),
				},
			],
		},
	],

	linkActiveClass: 'active', // active class for non-exact links.
	linkExactActiveClass: 'active', // active class for *exact* links.
});


function parseQueryString(query: string) {
	const vars = query.split('&');
	const query_string: any = {};
	for (const val of vars) {
		const pair = val.split('=');
		const key = decodeURIComponent(pair[0]);
		const value = decodeURIComponent(pair[1]);
		// If first entry with this name
		if (typeof query_string[key] === 'undefined') {
			query_string[key] = decodeURIComponent(value);
			// If second entry with this name
		} else if (typeof query_string[key] === 'string') {
			const arr = [query_string[key], decodeURIComponent(value)];
			query_string[key] = arr;
			// If third or later entry with this name
		} else {
			query_string[key].push(decodeURIComponent(value));
		}
	}
	return query_string;
}
let isReloading = false;
router.onError((error) => {
	console.error(error);
	if (/loading chunk \d* failed./i.test(error.message)) {
		window.location.reload();
	}
});

// beforeEnter navigation guard, afterEnter guard is dealed by App.vue and components
router.beforeEach((to, from, next) => {
	if (isReloading) {
		next(false);
	}
	if (globalx.hasUpdate) {
		window.location.href = process.env.BASE_URL + ((router.mode === 'hash') ? '/#' : '') + to.fullPath;
		isReloading = true;
		wait(500).then(() => {
			location.reload();
		});
	}
	if (router.mode === 'hash') {
		const search = window.location.search.substring(1);
		if (search !== '') {
			const parsedParam = parseQueryString(window.location.search.substring(1));

			for (const key in parsedParam) {
				if (key === '') { continue; }
				to.query[key] = parsedParam[key];
			}

			let newSearch = '';
			const keys = Object.keys(to.query);
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];
				const value = to.query[key];
				if (i === 0) { newSearch += '?'; } else { newSearch += '&'; }
				newSearch += key + '=' + value;
			}


			const href = process.env.BASE_URL + '#' + to.path + newSearch;
			// console.log('url remade:', href);
			isReloading = true;
			window.location.href = href;
			return;
		}
	}

	if (accountx.fullAccountData) {
		currentAccountId = accountx.fullAccountData.accountId;
	} else {
		currentAccountId = '';
	}

	// live demo login
	// if (to.query.livedemo) {
	// 	delete to.query.livedemo;
	// 	signInAsLiveDemo();
	// }

	const validation = navigationValidation(to, from);
	if (validation.newRoute) {
		next(validation.newRoute);
	} else if (validation.success) {
		next();
	} else {
		next(false);
	}
});
function appendSlash(list: string[]): string[] {
	const result: string[] = [];
	list.map((value) => {
		result.push(value, value + '/');
	});
	return result;
}
export const nonUserRelatedPath = appendSlash(['/', '/auth']);

export function navigationValidation(to: Route, from?: Route): NavigationValidation {
	if (isReloading) {
		return { success: false };
	}
	if (!from) { from = to; }
	// safe passage no matter login or not
	let allowedPaths = nonUserRelatedPath;
	if (allowedPaths.includes(to.path)) {
		return { success: true };
	}
	if (to.path === '/livedemo' || to.path === '/livedemo/') {
		userx.signInAsLiveDemo();
		return { success: false, newRoute: { path: '/', query: from.query, replace: true } };
	}
	// end safe passage
	if (userx.loginStatus === 'loading') {
		return { success: true };
	} else {
		if (userx.loginStatus === 'logged out') {

			const query = JSON.parse(JSON.stringify(from.query));

			allowedPaths = appendSlash(['/login', '/register', '/forgot-password']);
			if (allowedPaths.includes(to.path)) {
				// let hasOldQueries = true;
				// for (const key in query) {
				// 	if (query.hasOwnProperty(key)) {
				// 		if (!to.query[key]) {
				// 			hasOldQueries = false;
				// 		}
				// 	}
				// }
				// if (!hasOldQueries) {
				// 	const newRoute = { ...to, replace: true };
				// 	newRoute.query = { ...query, ...to.query };
				// 	console.log(newRoute.path);
				// 	return { success: true, newRoute };
				// }
				if (query.continueUrl && !to.query.continueUrl) {
					const newQuery = { ...to.query };
					newQuery.continueUrl = query.continueUrl;
					return { success: false, newRoute: { path: to.path, query: newQuery, replace: true } };
				}
				return { success: true };
			}
			query.continueUrl = to.fullPath;
			return { success: false, newRoute: { path: '/login', query, replace: true } };

		} else {
			// not allowed to
			const unallowedPath = appendSlash(['/login', '/register', '/forgot-password']);
			if (unallowedPath.includes(to.path)) {
				if (unallowedPath.includes(from.path)) {
					return { success: false, newRoute: { path: '/', replace: false } };
				}
				return { success: false };
			}
			if (userx.needCompleteRegistration) {
				if (to.path === '/complete-registration') {
					return { success: true };
				} else {
					const query = JSON.parse(JSON.stringify(from.query));
					query.continueUrl = to.fullPath;
					return { success: false, newRoute: { path: '/complete-registration', query, replace: true } };
				}
			} else {
				if (to.path === '/complete-registration') {
					if (from.path === '/complete-registration') {
						return { success: false, newRoute: { path: '/', replace: false } };
					}
					return { success: false };
				}
				return { success: true };
			}
		}
	}
}
export default router;
