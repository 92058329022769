import store from '@/store';
import userx from '@/store/modules/userx';
import fb from '@/store/gobi-firestore';
import accountx from './modules/accountx';
import {
	AccountData, SetPermmissionSubmission, permissionsGroups,
	AddRemoveAccountUserSubmission, PermissionsData, LoginStatus,
	ForecastDoc, SaveForecastDocSubmission, RemoveForecastDocSubmission,
} from '@/store/models.def';
import firebase from 'firebase/app';

const db: firebase.firestore.Firestore = fb.db;
const functions: firebase.functions.Functions = fb.functions;

export class CompanyAccount {

	public db: firebase.firestore.Firestore | null = null;
	public functions: firebase.functions.Functions | null = null;

	private _unsubscribeListenToAccount: (() => void) | null = null;
	private _unsubscribeListenToCurrentAccountPermissions: (() => void) | null = null;
	private _unsubscribeListenToForecastDocs: (() => void) | null = null;
	private _currentAccountId: string | null = null;

	constructor() {

		if (accountx && accountx.currentAccountId && accountx.currentAccountId !== 'loading') {
			this._currentAccountId = accountx.currentAccountId;
			this.init(accountx.currentAccountId);
		}

		store.watch(
			(states, getters) => getters['accountx/currentAccountId'],
			(newValue, oldValue) => {
				// seyaccountx.fullAccountData to null before loading a new one;
				accountx.updateAccount(null);
				if (oldValue) {
					this.dispose();
					accountx.updateCurrentAccountPermissions({});
				}
				if (!newValue || newValue === 'loading') {
					this.dispose();
				} else if (newValue && newValue !== 'loading') {
					this._currentAccountId = newValue;
					this.init(newValue);
				}
			});

		// store.subscribe((mutation, state: any) => {
		// 	if (mutation.type === 'accountx/m_updateAccountId') {
		// 		const newValue = state.accountx.setCurrentAccountId;

		// 		console.log('setCurrentAccountId changed', newValue);
		// 		if (!userx.visitedAccounts.includes(newValue)) {
		// 			const visitedAccounts = userx.visitedAccounts;
		// 			visitedAccounts.push(newValue);
		// 			userx.updateHistory({visitedAccounts});
		// 		}
		// 	}
		//   });
		// store.watch(
		// 	(states: any, getters) => states.userx && states.userx.loginStatus,
		// 	(newValue: LoginStatus, oldValue: LoginStatus) => {
		// 		if (newValue === 'logged out') {
		// 			this.dispose();
		// 			if (accountx) {
		// 				accountx.updateAccountId(null);
		// 			}
		// 		}
		// 	});
		store.watch(
			(states, getters) => getters['userx/loginStatus'],
			(newValue: LoginStatus, oldValue: LoginStatus) => {
				if (newValue === 'logged out') {
					this.dispose();
					if (accountx) {
						accountx.updateAccountId(null);
					}
				}
			});
		store.watch(
			(states: any, getters) => states.accountx && states.accountx.setCurrentAccountId,
			(newValue, oldValue) => {
				if (newValue) {
					if (!userx.visitedAccounts.includes(newValue)) {
						const visitedAccounts = userx.visitedAccounts;
						visitedAccounts.push(newValue);
						userx.updateHistory({ visitedAccounts });
					}
					this.updateVisitedToDatabase(newValue);
				}
			});

	}

	public async updateVisitedToDatabase(accountId: string) {

		if (userx.uid) {
			updateDb();
		} else {
			const unwatch = store.watch(
				(states, getters) => getters['userx/uid'],
				(newValue, oldValue) => {
					if (newValue) {
						unwatch();
						updateDb();
					}
				});
		}
		function updateDb() {
			db.doc(`users/${userx.uid}`).set({
				lastVisitedAccount: accountId,
				visitedAccounts: firebase.firestore.FieldValue.arrayUnion(accountId),
			}, { merge: true });
		}
	}

	public async init(accountId: string) {
		this._unsubscribeListenToAccount = db.doc(`accounts/${accountId}`)
			.onSnapshot(this.onAccountData,
				(error) => {
					console.error(error);
					accountx.isUnauthorized();
				});
		this._unsubscribeListenToCurrentAccountPermissions = db.doc(`accounts/${accountId}/permissions/users`)
			.onSnapshot(this.onAccountPermissions,
				(error) => {
					console.error(error);
				});
		this._unsubscribeListenToForecastDocs =
			db.collection(`accounts/${accountId}/forecasts`).orderBy('createdAt', 'desc').limit(10)
				.onSnapshot(this.onForecastDocs,
					(error) => {
						// console.error(error);
						accountx.unauthorizedForecast();
					});
	}

	public dispose() {
		if (this._unsubscribeListenToAccount) {
			this._unsubscribeListenToAccount();
		}
		if (this._unsubscribeListenToCurrentAccountPermissions) {
			this._unsubscribeListenToCurrentAccountPermissions();
		}
		if (this._unsubscribeListenToForecastDocs) {
			this._unsubscribeListenToForecastDocs();
		}
	}

	public onAccountData(doc: firebase.firestore.DocumentSnapshot) {
		if (doc.exists) {
			const data = doc.data() as AccountData;
			accountx.updateAccount(data);
		} else {
			accountx.isUnexisistent();
		}
	}
	public onAccountPermissions(doc: firebase.firestore.DocumentSnapshot) {
		if (!doc.exists) {
			accountx.updateCurrentAccountPermissions({});
			return;
		}

		const permissions = doc.data() as PermissionsData;
		delete permissions.emails;
		for (const permission of Object.values(permissions)) {
			if (permission.admin) {
				for (const key of permissionsGroups) {
					if (key !== 'global') {
						permission[key] = true;
					}
				}
			}
		}
		accountx.updateCurrentAccountPermissions(permissions);
	}
	public onForecastDocs(docs: firebase.firestore.QuerySnapshot) {
		if (!docs.empty) {
			accountx.updateAllForecasts(docs.docs.map((doc) => doc.data() as ForecastDoc));
		} else {
			accountx.updateAllForecasts([]);
		}
	}

	public async setPermissions(submission: SetPermmissionSubmission) {
		const { accountId, email } = submission;
		const permissions: string[] = permissionsGroups.slice(1);
		permissions.unshift('admin');

		const permission: any = {};
		for (const key of permissions) {

			permission[key] = (submission.admin) ? true : (submission as any)[key] || false;
		}
		const update: any = {};
		update[email] = permission;

		try {
			await db.doc(`accounts/${accountId}/permissions/users`).set(update, { merge: true });
			return { success: true };
		} catch (error) {
			console.error(error);
			return { success: false, error, errorCode: error.code, errorMessage: error.message };
		}
	}

	public async addRemoveAccountUser(submission: AddRemoveAccountUserSubmission) {
		const { remove, email } = submission;
		if (!remove) {
			this.inviteNewAccountUser(email);
		}
		try {
			const result = await functions
				.httpsCallable('addRemoveAccountUser')(submission);
			return { success: true };
		} catch (error) {
			console.error(error);
			return { success: false, error, errorCode: error.code, errorMessage: error.message };
		}
	}

	public async inviteNewAccountUser(email: string) {
		const isUserExist = await fb.isUserExist(email);
		if (!isUserExist && !userx.recentInvitedEmails.has(email)) {
			// console.log('send invitation to', email);
			userx.sendSignInEmail(email);
		}
	}

	public async saveForecast(submission: SaveForecastDocSubmission) {
		const { accountId, forecast } = submission;
		try {
			await db.doc(`accounts/${accountId}/forecasts/${forecast.docId}`).set(forecast, { merge: true });
			return { success: true };
		} catch (error) {
			console.error(error);
			return { success: false, error, errorCode: error.code, errorMessage: error.message };
		}
	}

	public async removeForecast(submission: RemoveForecastDocSubmission) {
		const { accountId, docId } = submission;
		try {
			await db.doc(`accounts/${accountId}/forecasts/${docId}`).delete();
			return { success: true };
		} catch (error) {
			console.error(error);
			return { success: false, error, errorCode: error.code, errorMessage: error.message };
		}
	}

}

const accountz = new CompanyAccount();
export default accountz;
