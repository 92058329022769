import userx from '@/store/modules/userx';
import { FirebaseSocket } from './firestore';
import { SetSupportLvlResponse, Signature } from '@/store/models.def';
import globalx from './modules/globalx';
import { TERMS_VERSION, PRIVACY_POLICY_VERSION } from '@/components/auth/termsPolicy/version';
import firebase from 'firebase/app';

let auth: firebase.auth.Auth;
let db: firebase.firestore.Firestore;
let functions: firebase.functions.Functions;
let storage: firebase.storage.Storage;


export class GobiFirebaseSocket extends FirebaseSocket {

	public constructor() {
		super('none');
		auth = this.auth;
		db = this.db;
		functions = this.functions;
		storage = this.storage;


		db.doc('system/gobiInfo').onSnapshot((doc) => {
			if (doc.exists) {
				const data = doc.data()!;
				const version = `${data.version}.${data.branchVersion}`;
				globalx.updateVersion(version);
			}
		}, (error) => {
			console.error('system to load system/gobiInfo');
			console.error(error);
		});

	}

	public async setSupportLvl(email: string, supportLvl: number): Promise<SetSupportLvlResponse> {
		const user = userx.user;
		if (!user) { return { success: false, email, supportLvl, errorMessage: 'No user' }; }

		const adminLvl = 10;
		const defaultAdminEmail = ['dragonwhites@gmail.com', 'master@gobi.my'];
		let authorized = false;

		// check request is made by an admin lvl support
		if (user.supportLvl && user.supportLvl >= adminLvl || defaultAdminEmail.includes(user.email)) { authorized = true; }
		if (!user.emailVerified) { authorized = false; }

		if (!authorized) {
			return {
				success: false, email, supportLvl,
				errorMessage: `Only level ${adminLvl} support with verified email can perform this action`,
			};
		}
		try {

			const haveUser = await this.isUserExist(email);
			if (!haveUser) {
				return {
					success: false, email, supportLvl,
					errorMessage: 'User does not exist.',
				};
			}
			await functions.httpsCallable('setSupportLvl')({ email, supportLvl });
			return { success: true, email, supportLvl } as SetSupportLvlResponse;
		} catch (error) {
			console.error(error);
			return {
				success: false,
				email,
				supportLvl,
				error,
				errorCode: error.detail || error.code,
				errorMessage: error.message,
			};
		}
	}

	public assertSupportLvl(requiredLvl: number) {
		const user = userx.user;
		if (!user) {
			return { success: false, errorCode: 'unauthenticated', errorMessage: 'No user' };
		}
		if (!user.emailVerified) {
			return { success: false, errorCode: 'unverified-email', errorMessage: 'Email not verified' };
		}
		if (user.supportLvl && user.supportLvl < requiredLvl) {
			return {
				success: false, errorCode: 'insufficient-support-level',
				errorMessage: `Only level ${requiredLvl} support or above can perform this action`,
			};
		}
		return { success: true };
	}

	public async updateDisplayName(displayName: string) {
		const result = await this.updateProfile({ displayName });
		if (result.success) {
			functions.httpsCallable('updateEmailDoc')({ displayName });
		}
		return result;
	}

	public signTerms(type: 'terms' | 'privacyPolicy') {
		const user = userx.user;
		if (!user) {
			throw new Error('No User');
		}
		const submissionType = type === 'terms' ? 'termsSignature' : 'privacySignature';
		const submission: any = {};
		submission[submissionType] = {
			signedAt: Date.now(),
			signedAtServerTime: firebase.firestore.FieldValue.serverTimestamp(),
			signedVersion: type === 'terms' ? TERMS_VERSION : PRIVACY_POLICY_VERSION,
		};
		db.doc(`users/${user.uid}`).set(submission, { merge: true });
	}

	protected _readExtraDocs(userObject: any) {
		const { email, displayName, emailVerified, uid } = this;
		const user = auth.currentUser!;

		// get user supportLvl
		user.getIdTokenResult().then((idTokenResult: firebase.auth.IdTokenResult) => {
			userObject.supportLvl = idTokenResult.claims.supportLvl || 0;
			userx.updateUser(userObject);
		});


		db.doc(`emails/${email}`).get().then(async (doc) => {
			if (doc.exists) {
				const data = doc.data()!;
				const accounts = data.accounts || {};
				userx.updateMyAccounts(accounts);

				if (data.uid !== this.uid || data.displayName !== this.displayName) {
					// update email doc and inherit old email doc
					const result = await functions.httpsCallable('updateEmailDoc')({ displayName });
					const resData = result.data;
					userx.updateMyAccounts(resData.accounts);
				}
			} else {
				// create new emaildoc and inherit old email doc
				const result = await functions.httpsCallable('updateEmailDoc')({ displayName });
				const resData = result.data;
				userx.updateMyAccounts(resData.accounts);
			}
		});
		db.doc(`users/${uid}`).get().then((doc) => {

			const updateUserDocData: any = { uid, email, lastLoginTime: Date.now() };

			if (doc.exists) {
				const data = doc.data()!;
				const lastVisitedAccount: string | null = data.lastVisitedAccount || null;
				const lastLoginTime: number | null = data.lastLoginTime || null;
				const visitedAccounts: string[] = data.visitedAccounts || [];
				const termsSignature: Signature | undefined = data.termsSignature;
				const privacySignature: Signature | undefined = data.termsSignature;
				// console.log('user', data);
				userx.updateHistory({
					lastVisitedAccount, lastLoginTime, visitedAccounts,
					termsSignature, privacySignature, userDocLoaded: true,
				});
			} else {
				userx.updateHistory({
					lastVisitedAccount: null, lastLoginTime: null,
					visitedAccounts: [], userDocLoaded: true,
				});
			}

			db.doc(`users/${uid}`).set(updateUserDocData, { merge: true });

		});
	}
}


const fb = new GobiFirebaseSocket();
export default fb;
