// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { TypicalResponse, LoginSubmission, User, ProfileSubmission } from '@/store/models.def';

import userx from '@/store/modules/userx';

// Your web app's Firebase configuration
// const firebaseConfig = {
// 	apiKey: 'AIzaSyA5SConG_Z798Bkt53Ugq7_ZPGlqsOmZaM',
// 	authDomain: 'gobi-9081.firebaseapp.com',
// 	databaseURL: 'https://gobi-9081.firebaseio.com',
// 	projectId: 'gobi-9081',
// 	storageBucket: 'gs://gobi-9081.appspot.com',
// 	messagingSenderId: '252957131638',
// 	appId: '1:252957131638:web:e13c8a4c97b0bbf1243057',
// };
const firebaseConfig = {
	apiKey: 'AIzaSyAc33IRkKWjUE_s7m5NvEGm31VTdLtxIYQ',
	authDomain: 'gobi-pro.firebaseapp.com',
	databaseURL: 'https://gobi-pro.firebaseio.com',
	projectId: 'gobi-pro',
	storageBucket: 'gobi-pro.appspot.com',
	messagingSenderId: '708253161895',
	appId: '1:708253161895:web:c834f0d2f691c3f9b27e82',
	measurementId: 'G-XJJQFQH4H8',
};
// tslint:disable:max-line-length
const actionCodeSettings: firebase.auth.ActionCodeSettings = { url: 'https://bi.sql.com.my/', handleCodeInApp: true };

export let auth: firebase.auth.Auth;
export let db: firebase.firestore.Firestore;
export let functions: firebase.functions.Functions;
export let storage: firebase.storage.Storage;

export class FirebaseSocket {
	public auth: firebase.auth.Auth;
	public db: firebase.firestore.Firestore;
	public functions: firebase.functions.Functions;
	public storage: firebase.storage.Storage;

	public email: string = '';
	public displayName: string = '';
	public emailVerified: boolean = false;
	public uid: string = '';

	public userObject: any = {};

	protected _emulator: 'functions' | 'firestore' | 'none' = 'none';

	public constructor(emulator: 'functions' | 'firestore' | 'none' = 'none') {
		// Initialize Firebase
		firebase.initializeApp(firebaseConfig);

		this.auth = auth = firebase.auth();
		this.db = db = firebase.firestore();
		this.functions = functions = firebase.app().functions('asia-east2');
		this.storage = storage = firebase.storage();

		// console.log('env:', process.env);
		this._emulator = emulator;
		if (process.env.NODE_ENV === 'development') {
			if (emulator === 'firestore') {
				db.settings({
					host: 'localhost:8081',
					ssl: false,
				});
				functions.useFunctionsEmulator('http://localhost:5001');
			} else if (emulator === 'functions') {
				functions.useFunctionsEmulator('http://localhost:5000');
			}
		}

		// for easy debug
		(window as any).firebase = firebase;
		(window as any).functions = functions;
		(window as any).db = db;
		(window as any).auth = auth;
		(window as any).storage = storage;

		auth.onAuthStateChanged((user) => {
			if (user) {
				// User is signed in.
				// console.log('sign in');

				const email = this.email = user.email || '';
				const displayName = this.displayName = user.displayName || '';
				const emailVerified = this.emailVerified = user.emailVerified;
				const uid = this.uid = user.uid;

				this.userObject = { uid, email, displayName, emailVerified };
				const userObject = this.userObject;
				userx.loginLocal(userObject);

				// see if user have set their password
				auth.fetchSignInMethodsForEmail(email).then((list) => {
					// console.log(idTokenResult.claims)
					if (list.includes('password')) {
						userObject.noPassword = false;
					} else {
						userObject.noPassword = true;
					}
					userx.updateUser(userObject);
				});

				this._readExtraDocs(userObject);

			} else {
				// No user is signed in.
				// console.log('sign out');
				userx.logoutLocal();
			}
		});
	}


	public async loginUser(user: LoginSubmission): Promise<TypicalResponse> {
		try {
			const response = await auth.signInWithEmailAndPassword(user.email, user.password);
			return { success: true };
		} catch (error) {
			console.error(error);
			return { success: false, error, errorCode: error.code, errorMessage: error.message };
		}
	}

	public async logoutUser() {
		await auth.signOut();
	}

	public async updateProfile(profile: ProfileSubmission): Promise<TypicalResponse> {
		try {
			if (!auth.currentUser) { return { success: false, errorMessage: 'No user' }; }
			await auth.currentUser.updateProfile(profile);
			return { success: true };
		} catch (error) {
			console.error(error);
			return { success: false, error, errorCode: error.code, errorMessage: error.message };
		}
	}

	public async updatePassword(password: string): Promise<TypicalResponse> {
		try {
			if (!auth.currentUser) { return { success: false, errorMessage: 'No user' }; }
			await auth.currentUser.updatePassword(password);
			return { success: true };
		} catch (error) {
			console.error(error);
			return { success: false, error, errorCode: error.code, errorMessage: error.message };
		}
	}
	public async signInAndResetPassword(oldPassword: string, newPassword: string): Promise<TypicalResponse> {
		try {
			if (!auth.currentUser) { return { success: false, errorMessage: 'No user' }; }
			await auth.signInWithEmailAndPassword(auth.currentUser.email!, oldPassword);
			await auth.currentUser.updatePassword(newPassword);
			return { success: true };
		} catch (error) {
			console.error(error);
			return { success: false, error, errorCode: error.code, errorMessage: error.message };
		}
	}

	public async sendSignInEmail(email: string): Promise<TypicalResponse> {
		try {
			window.localStorage.setItem('emailForSignIn', email);

			await auth.sendSignInLinkToEmail(email, actionCodeSettings);
			return { success: true };
		} catch (error) {
			console.error(error);
			return { success: false, error, errorCode: error.code, errorMessage: error.message };
		}
	}

	public async sendEmailVerification() {
		try {
			if (!auth.currentUser) { return { success: false, errorMessage: 'No user' }; }
			await auth.currentUser.sendEmailVerification(actionCodeSettings);
			return { success: true };
		} catch (error) {
			console.error(error);
			return { success: false, error, errorCode: error.code, errorMessage: error.message };
		}
	}

	public async sendPasswordResetEmail(email: string): Promise<TypicalResponse> {
		try {
			await auth.sendPasswordResetEmail(email, actionCodeSettings);
			return { success: true };
		} catch (error) {
			console.error(error);
			return { success: false, error, errorCode: error.code, errorMessage: error.message };
		}
	}
	public async isUserExist(email: string) {
		const haveUser = await auth.fetchSignInMethodsForEmail(email);
		if (haveUser.length > 0) {
			return true;
		}
		return false;
	}

	protected _readExtraDocs(userObject: any) {
	}
}
